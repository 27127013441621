import { MapCoordinate } from 'src/types/MapTypes'

export enum Currency {
    EUR = 'EUR',
    HUF = 'HUF',
    USD = 'USD',
    GBP = 'GBP',
    AED = 'AED',
    AED_AR = 'د.إ',
}

export enum ProductType {
    MONTHLY = 'MONTHLY',
    DAILY = 'DAILY',
}

// TODO: once the migration to unified product is done, we should cleanup the 'subscription' endpoints
export function productTypeToUrlParam(productType: ProductType, isSubscription?: boolean): string {
    if (isSubscription && productType === ProductType.MONTHLY) return 'subscription'
    return productType.toLowerCase()
}

export type DayOfWeek = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'
export type D2DHours = Record<DayOfWeek, { start: string; end: string }>
export type D2DHoursCollection = Record<DayOfWeek, { start: string; end: string }[]>
export type SelfPickupHours = Record<DayOfWeek, string>
export type SelfPickupHoursCollection = Record<DayOfWeek, string[]>

export type CitySettingsResponse = {
    id: number
    cityId: number
    cityCode: string
    currency: Currency
    timezone: string
    deliveryFee: number
    minMonthlyRentalFee: number
    maxMonthlyRentalFee: number
    minRentalPeriod: number
    minDepositMonthsToCharge: number
    maxDepositMonthsToCharge: number
    shortTermPeriodDays: number
    shortTermSurchargePercent: number
    freeCancellationPeriodDays: number
    returnNoticePeriodDays: number
    allowedKmsPerYear: number
    validFrom: string
    createdBy: string
    createdAt: string
    vatPercentage: number
    shortNoticePenaltyFee: number
    deliveryIntervalLengthMinutes: number
    minDepositAmount: number
    maxStartDateOffset: number
    minSearchPrice: number
    maxSearchPrice: number
    availableLocales: { locale: string; language: string; default: boolean }[]
    secondaryDriverFee: number
    topUpPreChargeDays: number
    productType: ProductType
    businessZone: MapCoordinate[]
    customerServiceInfo: { businessHours: Partial<D2DHours> }
    holidays: []
    businessHours: D2DHours
    nextDayDeliveryLimits: { availableUntil: string; limitedFrom: string; earliestTimeAfterLimit: string }
    breakInBusinessHours: Partial<D2DHours>
    handbackChangeAfterLimitHours: number
    handbackChangeBeforeLimitHours: number
    coordinates: MapCoordinate
    luxuryCarDeposit: number
}

export type CityDetails = {
    id: number
    code: string
    currency: string
    translations: Translation[]
    public: boolean
    timezone: string
    createdBy: string
    createdAt: Date
    updatedBy: string
    updatedAt: Date
}

export type Translation = {
    language: string
    name: string
}
