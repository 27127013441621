import { axios } from '@util/axios'
import { config } from '@util/config'

import {
    AddressResponse,
    BookingPriceCalculation,
    CancellationFeeResponse,
    CareemStatusResponse,
    CustumerInvoiceDocument,
    DailyPriceCalculation,
    MonthlyHandbackDateUpdateResponse,
    MonthlyPriceCalculation,
    PaymentBooking,
    UserBillingAddressResponse,
    UserEditBillingAddress,
    AllowedPaymentMethods,
    PaymentIntentStatusResponse,
    CardNetwork,
    PaymentMethodMadaStatus,
    BookingCharge,
} from './payment.types'
import { ServiceResponse } from '../types/global'
import { ProductType, productTypeToUrlParam } from '@service/configuration.types'

export const PAYMENT_API_URL = `${config.BASE_URL}/payment`

export type PaymentService = {
    getUserBillingAddress: (externalCustomerId: string) => ServiceResponse<UserBillingAddressResponse>
    createOrUpdateUserBillingAddress: (
        externalCustomerId: string,
        userBillingAddress: UserEditBillingAddress,
    ) => ServiceResponse<UserBillingAddressResponse>
    postCareemPayment: (externalCustomerId: string) => ServiceResponse<CareemStatusResponse>
    getCareemPayment: (externalCustomerId: string, careemInvoiceId: string) => ServiceResponse<CareemStatusResponse>
    getPaymentIntentStatus: (bookingId: number) => ServiceResponse<PaymentIntentStatusResponse>
    getPaymentIntentStatusById: (bookingId: number, paymentId: string) => ServiceResponse<PaymentIntentStatusResponse>
    getRentalDetails: (
        externalCustomerId: string,
        bookingId: number,
        productType?: ProductType,
    ) => ServiceResponse<PaymentBooking>
    getAddress: () => ServiceResponse<AddressResponse>
    getDailyCancellationFee: (customerId: string, bookingId: number) => ServiceResponse<CancellationFeeResponse>
    getMonthlyCancellationFee: (customerId: string, bookingId: number) => ServiceResponse<CancellationFeeResponse>
    getInvoices: (
        externalCustomerId: string,
        bookingId: number,
        productType?: ProductType,
    ) => ServiceResponse<CustumerInvoiceDocument[]>
    generateInvoiceAsPdf: (invoiceId: number) => ServiceResponse<any>
    postCheckoutToken: (
        token: string,
        externalCustomerId: string,
        currency: string,
        cardNetwork: CardNetwork,
        city: string,
    ) => ServiceResponse<{ _links: Record<string, { href: string }> }>
    getAllowedPaymentMethods: (externalCustomerId: string) => ServiceResponse<AllowedPaymentMethods>
    getPaymentMethodMadaStatus: (paymentMethodId: number) => ServiceResponse<PaymentMethodMadaStatus>
    updateMonthlyHandbackDate: (
        externalCustomerId: string | number,
        bookingId: string | number,
        endDate: string,
    ) => ServiceResponse<MonthlyHandbackDateUpdateResponse>
    getChargesForBooking: (bookingId: string | number, externalCustomerId: string) => ServiceResponse<BookingCharge[]>
    getDocumentFile: (chargeId: string | number, documentKey: string) => ServiceResponse<Blob>
    postPaymentMethodUpdate: (customerId: string | number, bookingId: string | number) => ServiceResponse<any>
}

export const paymentService: PaymentService = {
    getUserBillingAddress: async (externalCustomerId) => {
        return await axios.get(`${PAYMENT_API_URL}/customers/${externalCustomerId}/billing-details`)
    },
    createOrUpdateUserBillingAddress: async (externalCustomerId, userBillingAddress) => {
        return await axios.put(`${PAYMENT_API_URL}/customers/${externalCustomerId}/billing-details`, {
            ...userBillingAddress,
        })
    },
    getCareemPayment: async (externalCustomerId, paymentRefId) => {
        return await axios.get(`${PAYMENT_API_URL}/customers/${externalCustomerId}/careem/intents/${paymentRefId}`)
    },
    getPaymentIntentStatus: async (bookingId: number) => {
        return await axios.get(`${PAYMENT_API_URL}/booking-payment/intent/${bookingId}/initial`)
    },
    getPaymentIntentStatusById: async (bookingId: number, paymentId: string) => {
        return await axios.get(`${PAYMENT_API_URL}/booking-payment/intent/${bookingId}/${paymentId}`)
    },
    getRentalDetails: async (
        externalCustomerId: string,
        bookingId: number,
        productType: ProductType = ProductType.DAILY,
    ) => {
        return await axios.get(
            `${PAYMENT_API_URL}/customers/${externalCustomerId}/bookings/${productTypeToUrlParam(
                productType,
                true,
            )}/${bookingId}`,
        )
    },
    postCareemPayment: async (externalCustomerId) => {
        return await axios.post(`${PAYMENT_API_URL}/customers/${externalCustomerId}/careem/intents`, {})
    },
    getAddress: async () => {
        return await axios.post(`${PAYMENT_API_URL}/customers/careem/intents`)
    },
    getDailyCancellationFee: async (customerId, bookingId) => {
        return await axios.get(
            `${PAYMENT_API_URL}/customers/${customerId}/bookings/${bookingId}/daily/cancellation-fee`,
        )
    },
    getMonthlyCancellationFee: async (customerId, bookingId) => {
        return await axios.get(
            `${PAYMENT_API_URL}/customers/${customerId}/bookings/${bookingId}/monthly/cancellation-fee`,
        )
    },
    getInvoices: async (
        externalCustomerId: string,
        bookingId: number,
        productType: ProductType = ProductType.DAILY,
    ) => {
        return axios.get(
            `${PAYMENT_API_URL}/customers/${externalCustomerId}/bookings/${productType.toLowerCase()}/${bookingId}/invoices`,
        )
    },
    generateInvoiceAsPdf: async (invoiceId: number) => {
        return axios.get(`${PAYMENT_API_URL}/invoices/pdf/${invoiceId}`, { responseType: 'blob' })
    },
    postCheckoutToken: async (
        token: string,
        externalCustomerId: string,
        currency: string,
        cardNetwork: CardNetwork,
        cityCode: string,
    ) => {
        return await axios.post(`${PAYMENT_API_URL}/customers/${externalCustomerId}/checkout/card-token`, {
            cardToken: token,
            currency,
            cardNetwork,
            cityCode,
        })
    },
    getAllowedPaymentMethods: async (externalCustomerId: string) => {
        return await axios.get(`${PAYMENT_API_URL}/methods/${externalCustomerId}/allowed`)
    },
    getPaymentMethodMadaStatus: async (paymentMethodId: number) => {
        return await axios.get(`${PAYMENT_API_URL}/methods/${paymentMethodId}/mada`)
    },
    updateMonthlyHandbackDate: async (
        externalCustomerId: string | number,
        bookingId: string | number,
        endDate: string,
    ) => {
        return await axios.patch(`${PAYMENT_API_URL}/customers/${externalCustomerId}/bookings/${bookingId}/end-date`, {
            endDate,
            dryRun: true,
        })
    },
    getChargesForBooking: async (bookingId: string | number, externalCustomerId: string) => {
        return axios.get(`${PAYMENT_API_URL}/customers/${externalCustomerId}/bookings/${bookingId}/charge-summary`)
    },
    getDocumentFile: async (chargeId: string | number, documentKey: string) => {
        return axios.get(`${PAYMENT_API_URL}/customers/charges/${chargeId}/documents/${documentKey}`, {
            responseType: 'blob',
        })
    },
    postPaymentMethodUpdate: async (customerId: string | number, bookingId: string | number) => {
        return await axios.post(
            `${PAYMENT_API_URL}/customers/${customerId}/bookings/${bookingId}/pre-auth`,
            {},
            {
                headers: { 'Content-Type': 'application/json' },
            },
        )
    },
}

export const pricingService = {
    getActivePriceCalculation: async (listingId: number | string, product: ProductType) => {
        return await axios.get(
            `${PAYMENT_API_URL}/listings/${listingId}/prices/${productTypeToUrlParam(product, true)}/active`,
        )
    },
    postPriceCalculation: async (calculationData: DailyPriceCalculation) => {
        return await axios.post(`${PAYMENT_API_URL}/listings/price-calculation/daily`, calculationData)
    },
    postPriceCalculationForMonthly: async (calculationData: MonthlyPriceCalculation) => {
        // TODO: remove "subscription" from URL
        return await axios.post(`${PAYMENT_API_URL}/listings/price-calculation/subscription`, calculationData)
    },
    bookingPriceCalculation: async (
        customerId: string,
        bookingId: string,
        calculationData: BookingPriceCalculation,
        type: ProductType = ProductType.DAILY,
    ) => {
        // TODO: remove "subscription" from URL
        return await axios.post(
            `${PAYMENT_API_URL}/customers/${customerId}/bookings/${productTypeToUrlParam(
                type,
                true,
            )}/${bookingId}/price-calculation`,
            calculationData,
        )
    },
}

export enum paymentCacheKeys {
    USER_BILLING_ADDRESS = 'USER_BILLING_ADDRESS',
    USER_ADDRESS = 'USER_ADDRESS',
}
